/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-global-assign */
/**
 * @title App
 * @description Application entry point
  */

// Jquery
window.jQuery = $;
window.$ = $;
jQuery = $;

// Barba - pages transition
import barba from "@barba/core";
import barbaCss from "@barba/css";

import Flickity from "flickity";
import "flickity/css/flickity.css";
require("flickity-fade");
require('flickity-imagesloaded');

// Modal
import modal from "jquery-modal";

// Lazyload image
import LazyLoad from "vanilla-lazyload";

// Components
import spinner from "./components/spinner";

var imagesLoaded = require("imagesloaded");

import SimpleBar from 'simplebar';
import { Loader } from '@googlemaps/js-api-loader';

var bodyScrollLock = require('body-scroll-lock');
var disableBodyScroll = bodyScrollLock.disableBodyScroll;
var enableBodyScroll = bodyScrollLock.enableBodyScroll;

import lightGallery from 'lightgallery';
import lgVideo from 'lightgallery/plugins/video';

// Modules
import navbar from "../modules/navbar/navbar";
import thomDropdown from "../modules/dropdown/dropdown";
import {gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
import thomForm from "./components/thom_form";
// import thomsearch from "./components/thom_advanced_search";
import Pagination from 'tui-pagination';

function FiltersAndPagination() {
  var filterdata = {};
  var pageN = 1;
  // var isPageClick = false;
  var filters = $('.thom-search--filter-block');   
  // console.log(filters)
  // filters click
  filters.each(function(){
    var _ = $(this);
    _.find('.thom-search--filter-btn').on('click', function (e) {      
      // isPageClick = false;
      pageN = 1;
      initPagination = false;
      paginationContainer.style.opacity = 0;
      _.find('.thom-search--filter-btn').removeClass('is-selected');
      $(this).addClass('is-selected');
      // $('.thom-search--text-input').val('');
      GetSearchResults();
    });
  });

  // pagination
  let initPagination = false;  
  let paginationContainer = document.querySelector('.thom-search--pagination .pagination');
  paginationContainer.style.opacity = 0;
  let resultsxpage = paginationContainer.dataset.resultsxpage;
  let paginationOptions = {
    totalItems: 1,
    itemsPerPage: resultsxpage,
    visiblePages: 4,
    page: 1,
    centerAlign: false,
    firstItemClassName: 'tui-first-child',
    lastItemClassName: 'tui-last-child',
    template: {
      page: '<a href="#" class="tui-page-btn page-item" data-val="{{page}}">{{page}}</a>',
      currentPage: '<a class="tui-page-btn tui-is-selected page-item is-selected" data-val="{{page}}">{{page}}</a>',
      moveButton:
        '<a href="#" class="tui-page-btn tui-{{type}} page-item">' +
          '<span class="tui-ico-{{type}} ico-{{type}}"></span>' +
        '</a>',
      disabledMoveButton:
        '<span class="tui-page-btn tui-is-disabled tui-{{type}}">' +
          '<span class="tui-ico-{{type}}">{{type}}</span>' +
        '</span>',
      moreButton:
        '<a href="#" class="tui-page-btn tui-{{type}}-is-ellip page-item">' +
        '<span class="tui-ico-ellip">...</span>' +
      '</a>'
    }
  };

  let paginationInstance = new Pagination(paginationContainer, paginationOptions);

  paginationInstance.on('beforeMove', function(eventData) {
    pageN = eventData.page;
    // console.log('beforeMove page = ' + pageN)
  });

  paginationInstance.on('afterMove', function(eventData) {
    pageN = eventData.page;
    // console.log('afterMove page = ' + pageN)
    GetSearchResults();
  });

  function GetFilters(){
    for( var i = 0; i < filters.length; i++){
      var filterId = $(filters[i]).attr('id');
      var filterValue = $(filters[i]).find('.thom-search--filter-btn.is-selected').data('val');
      // console.log(filterValue)
      if(filterValue === undefined){
        filterValue = null;
      }
      filterdata[filterId] = filterValue
    }

    // current page
    filterdata[$('.pagination').attr('id')] = pageN;

    return filterdata;
  }

  function GetSearchResults(){
    $(".page-loader").addClass("show");
    // console.log(GetFilters())
    $.ajax({
      type: 'POST',
      // contentType: 'application/json;charset=utf-8',
      url: THOM_searchUrlContent,
      data: GetFilters(),
      dataType: 'html',
      success: function (data) {  
        // console.log(data)
        $('#search-result').html(data);
      },
      error: function (xhr, ajaxOptions, thrownError) {
        console.log('error')
        console.log(xhr);
        console.log(ajaxOptions);
      },
      complete: function (xhr) {
        // console.log('complete')
        // console.log(xhr);
        if(xhr.statusText === "success" || xhr.statusText === "OK") {  
          // console.log('1')
          var totalresult = $('#search-result').find('.news-list').data('result');
          
          if(initPagination === false){
            paginationInstance.reset(totalresult);
            initPagination = true;
          }

          myLazyLoad.update();

          $('html,body').animate({
            scrollTop: 0
          }, 600);
          
          $(".page-loader").removeClass("show");
         
          if(totalresult <= resultsxpage){
            document.querySelector('.thom-search--pagination').style.display = 'none';
          } else {
            document.querySelector('.thom-search--pagination').style.display = 'block';
          }

          paginationContainer.style.opacity = 1;
        }
      }
    })
  }

  GetSearchResults();
}

// exist element
$.fn.exists = function () {
  return this.length !== 0;
};

gsap.config({
  nullTargetWarn: false
});

// Modal default options
$.modal.defaults = {
  clickClose: true,
  escapeClose: true,
  fadeDuration: 250,
  fadeDelay: 1.1,
  showClose: false,
  spinnerHtml: '<div id="spinner" class="default"><div class="sk-chase"><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div><div class="sk-chase-dot"></div></div></div>',
  blockerClass: "jquery-modal"
}

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      return "tablet";
  }
  else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
      return "mobile";
  }
  return "desktop";
};

var myLazyLoad;
let timelineInterval;

// Form Response Action
function MyThomFormResponseAction(title, text) {
  var mytitle;
  if (title !== undefined && title !== null && title !== "") {
    mytitle = "<div class='title md'><strong>" + title + "</strong></div>";
  } else {
    mytitle = "";
  }

  var myHtml =
    '<div class="modal modal-callback">' +
      '<a href="#close" rel="modal:close" class="close"></a>' +
      mytitle +
      "<div class='paragraph md'>" + text + "</div>"; +
    '</div>';

  $(myHtml).appendTo("body").modal({
    blockerClass: "dialog"
  });
}

let grecaptchaBadgeShow = () => {
  $(".grecaptcha-badge").removeClass('hide');
}
let grecaptchaBadgeHide = () => {
  $(".grecaptcha-badge").addClass('hide');
}

// Job form append
function jobControl(item) {
    
  if(item.hasClass('jobs--item')){
    
    var content = item.find('.jobs--item--content');
    var title = item.attr('data-job-title');
    var id = parseInt(item.attr("data-job-id"));

    $("#formJob").hide().appendTo(content).slideDown(100, function(){
      // Reset form
      $("#formJobOpportunities").resetForm();
      $("#formJobOpportunities .thom-form__error").hide(0);

      if ($("#formJobOpportunities").find('input[type=file]').exists()) {
        $("#formJobOpportunities .thom-form-selectfile__label span").html($("#formJobOpportunities").find('input[type=file]').attr('placeholder'))
      }      
      
      // Passo valori a campi nascosti
      $("#formJobOpportunities #TitoloLavoro").val(title);
      $("#formJobOpportunities #JobId").val(id);

      // Se id 0 mostro campi per Candidatura spontanea
      if(id == 0){
        $('#formJob').find('.only-for-free-application').show();
        $('#formJob').find('.only-for-free-application-el').attr('data-val-required', true);
      } else {
        $('#formJob').find('.only-for-free-application').hide();
        $('#formJob').find('.only-for-free-application-el').attr('data-val-required', false);
      }

      // Scroll update e riposizionamento
      setTimeout(function(){
        $('html,body').animate({
          scrollTop: item.offset().top - $('.navbar').height() * 2
        }, 300);
      }, 200);
    });
  }
}

// Start APP
function myapp() {

  $('img')
    .on('dragstart', function(event) { 
      event.preventDefault(); 
    })
    .on('contextmenu', function(e) {
      return false;
    });
  
  myLazyLoad = null;
  myLazyLoad = new LazyLoad({
    unobserve_entered: true,
    elements_selector: ".lazy",
  });

  $('.has-submenu').on({
    "mouseenter" : function(e){
      var _delay = ($(this).hasClass("has-submenu")) ? 200 : 0 ;
      $(this).find(".submenu").stop(true);
      $(this).find(".submenu").delay(_delay).addClass('show');
    },
    "mouseleave" : function(e){
      $(this).find(".submenu").stop(true);
      $(this).find(".submenu").delay(50).removeClass('show');
    },
  })

  // Dropdown è resa globale
  window.thomDropdown = thomDropdown;

  window.thomDropdown();

  if($('.main-form').length > 0){
    ScrollTrigger.create({
      trigger: '.main-form',
      start: "top 100%",
      end: "bottom 0",
      // once: true,
      // markers: true,
      onEnter: grecaptchaBadgeShow,
      onEnterBack: grecaptchaBadgeShow,
      onLeave: grecaptchaBadgeHide,
      onLeaveBack: grecaptchaBadgeHide
    });
  }

  if($('.event-alert').length > 0){
    $('.event-alert .close-alert').on('click', function(e){
      e.preventDefault();
      $('.event-alert').slideUp();
    })
  }
  
  // Home Main Slider 
  if(typeof(document.querySelector(".main-slider")) != 'undefined' && document.querySelector(".main-slider") != null){
    const mainSlider = document.querySelector(".main-slider .slideshow");
    let autoplayTime = 0;
    if(mainSlider.getAttribute('data-autoplay')){
      autoplayTime = Number(mainSlider.getAttribute('data-autoplay'))
    }
    
    var mainSliderFlkty = new Flickity( mainSlider, {
      autoPlay: autoplayTime,
      cellSelector: '.item',
      draggable: false,
      fade: true,
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: true,
      on: {
        ready: function() {
          if($('.main-slider .flickity-page-dots .dot').length > 1){
            $('.main-slider .flickity-page-dots').show();
          }
        }
      }
    });

    // mainSliderFlkty.on('pointerUp', function (event, pointer) {
    //   console.log(event)
    //   mainSliderFlkty.player.play();
    // });

    var mainSliderTl = gsap.timeline({
      scrollTrigger: {
        trigger: document.querySelector(".main-slider"),
        start: "top 80%",
        end: "bottom 15%",
        // markers: true,
        onEnter: function(){
          mainSliderStart($(".main-slider .slideshow .item.is-selected"));
        },
        onEnterBack: function(){
          mainSliderStart($(".main-slider .slideshow .item.is-selected"));
        },
        onLeave: function(){
          $(".main-slider .slideshow .item").each(function(){
            if($(this).find(".video").length > 0){
              var videoElement = $(this).find(".video")[0];
              videoStop($(this), videoElement);
            }
          });
        },
        onLeaveBack: function(){
          $(".main-slider .slideshow .item").each(function(){
            if($(this).find(".video").length > 0){
              var videoElement = $(this).find(".video")[0];
              videoStop($(this), videoElement);
            }
          });
        },
      }
    });

    mainSliderFlkty.on('change', function(index) {
      mainSliderFlkty.player.play();
      $(".main-slider .slideshow .item").each(function(){
        if($(this).hasClass('is-selected')){
          mainSliderStart($(this));
        } else {
          if($(this).find(".video").length > 0){
            var videoElement = $(this).find(".video")[0];
            videoStop($(this), videoElement);
          }
        }
      });
    });

    var videoPlay = function(item, itemVideo){
      item.addClass('is-playing');
      itemVideo.play();
      item.find('.state').removeClass('stop');
    }

    var videoStop = function(item, itemVideo){
      item.removeClass('is-playing');
      itemVideo.pause();
    }

    var mainSliderStart = function(item){
      var $this = item;
      if($this.find(".video").length > 0){
        var videoElement = $this.find(".video")[0];
        
        if(!$this.hasClass('videoloaded')){
          let videoSrc = $this.find(".video").data('src');
          videoElement.src = videoSrc;
          videoElement.load();
          videoElement.addEventListener('canplay', (event) => {
            $this.addClass('videoloaded');
            videoPlay($this, videoElement);
          });
          videoElement.addEventListener('ended', (event) => {
            mainSliderFlkty.next(true, false);
          });

          if($this.find('.video--controls').length > 0){
            var controls = $this.find('.video--controls');
            var restart = controls.find('.skip-start');
            var volume = controls.find('.volume');
            var state = controls.find('.state');
            var progress = controls.find('.progress-bar');
    
            if(window.matchMedia("(min-width: 920px)").matches){
              setTimeout(function(){
                controls.fadeOut();
              }, 2000);
    
              $this.hover(function(){
                controls.fadeIn();
              }, function(){
                controls.fadeOut();
              })
            }
    
            restart.on('click', function(){
              videoElement.pause();
              videoElement.currentTime = '0';
              videoElement.play();
              state.removeClass('stop');
            });
    
            volume.on('click', function(){
              $(this).toggleClass('on');
              if($(this).hasClass('on')){
                videoElement.muted = false;
              } else {
                videoElement.muted = true;
              }
            });
    
            state.on('click', function(){
              $(this).toggleClass('stop');
              if($(this).hasClass('stop')){
                videoElement.pause();
              } else {
                videoElement.play();
              }
            });
          }
        }else{
          videoPlay($this, videoElement);
        }
      }
    }
  }

  // Carousel
  if(typeof(document.querySelector(".carousel")) != 'undefined' && document.querySelector(".carousel") != null){
    const carousel = document.querySelector(".carousel");
    
    var carouselFlkty = new Flickity( carousel, {
      autoPlay: 0,
      groupCells: true,
      // cellAlign: 'left',
      contain: true,
      cellSelector: '.item',
      prevNextButtons: false,
      pageDots: true,
      lazyLoad: true,
      on: {
        ready: function() {
          if($('.carousel .flickity-page-dots .dot').length > 1){
            $('.carousel .flickity-page-dots').show();
          }

          $('.item').each(function(){
            var $this = $(this);

            if($this.find('.item--media')){
              var $thisMedia = $(this);
              if($this.find(".video--item").length > 0){
                var videoElement = $this.find(".video--item")[0];
                let videoSrc = $this.find(".video--item").data('src');
        
                videoElement.src = videoSrc;
                videoElement.load();

                $this.on({
                  "mouseenter" : function(e){
                    var _delay = ($this.find(".video--item")) ? 200 : 0 ;
                    $this.find('.item--media').addClass('is-playing');
                    videoElement.play();
                  },
                  "mouseleave" : function(e){
                    $this.find('.item--media').removeClass('is-playing');
                    videoElement.pause();
                  },
                })
              }

              
            }
          })
        }
      }
    });

  }

  // Fade in animation
  const animFadeInEl = gsap.utils.toArray('.anim__fadeIn');
  // Set things up
  gsap.set(animFadeInEl, {autoAlpha: 0, yPercent: 5});
  animFadeInEl.forEach((el, i) => {
    // Set up your animation
    const animFadeInAnim = gsap.to(el, {duration: 1, autoAlpha: 1, yPercent: 0, paused: true});
    // Use callbacks to control the state of the animation
    ScrollTrigger.create({
      trigger: el,
      start: "top 90%",
      end: "bottom 90%",
      once: true,
      // markers: true,
      onEnter: self => {
        // If it's scrolled past, set the state
        // If it's scrolled to, play it
        self.progress === 1 ? animFadeInAnim.progress(1) : animFadeInAnim.play()
      }
    });
  });

  // Scroll Up in animation
  const animScrollUpEl = gsap.utils.toArray('.anim__scrollUp');
  ScrollTrigger.matchMedia({"(min-width:640px)": function() {
      animScrollUpEl.forEach((el, i) => {
        let animScrollUpTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom 8%",
            scrub: 0.5,
            // markers: true
          }
        });
        animScrollUpTl.to(el, {
          yPercent: -5,
          ease: "power1.inOut"
        })
      });
    }
  })

  // Scroll Down in animation
  const animScrollDownEl = gsap.utils.toArray('.anim__scrollDown');
  ScrollTrigger.matchMedia({"(min-width:640px)": function() {
      animScrollDownEl.forEach((el, i) => {
        let animScrollDownTl = gsap.timeline({
          scrollTrigger: {
            trigger: el,
            start: "top bottom",
            end: "bottom 8%",
            scrub: 1,
            // markers: true
          }
        });
        animScrollDownTl.to(el, {
          yPercent: 5,
          ease: "power1.inOut"
        })
      });
    }
  })

  // Image ZoomIn animation
  const animZoomInEl = gsap.utils.toArray('.anim__zoomIn');
  animZoomInEl.forEach((el, i) => {
    let animZoomInTl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "center bottom",
        end: "center top",
        scrub: 1,
        // markers: true
      }
    });
    animZoomInTl.to(el, {
      scale: 1.1,
      ease: "power1.inOut"
    })
  });

  // Image ZoomOut animation
  const animZoomOutEl = gsap.utils.toArray('.anim__zoomOut');
  gsap.set(animZoomOutEl, {scale: 1.1});
  animZoomOutEl.forEach((el, i) => {
    let animZoomOutTl = gsap.timeline({
      scrollTrigger: {
        trigger: el,
        start: "center bottom",
        end: "center top",
        scrub: 1,
        // markers: true
      }
    });
    animZoomOutTl.to(el, {
      scale: 1,
      ease: "power1.inOut"
    })
  });

  // video media / Scrolltrigger
  if($('.media').exists()){
    $('.media').each(function(){
      var $this = $(this);
      if($this.find(".video--item").length > 0){
        var videoElement = $this.find(".video--item")[0];
        let videoSrc = $this.find(".video--item").data('src');

        videoElement.src = videoSrc;
        videoElement.load();



        if($this.find('.video--controls').length > 0){
          var controls = $this.find('.video--controls');
          var restart = controls.find('.skip-start');
          var volume = controls.find('.volume');
          var state = controls.find('.state');
          var progress = controls.find('.progress-bar');
  
          if(window.matchMedia("(min-width: 920px)").matches){
            setTimeout(function(){
              controls.fadeOut();
            }, 2000);
  
            $this.hover(function(){
              controls.fadeIn();
            }, function(){
              controls.fadeOut();
            })
          }
  
          restart.on('click', function(){
            videoElement.pause();
            videoElement.currentTime = '0';
            videoElement.play();
            state.removeClass('stop');
          });
  
          volume.on('click', function(){
            $(this).toggleClass('on');
            if($(this).hasClass('on')){
              videoElement.muted = false;
            } else {
              videoElement.muted = true;
            }
          });
  
          state.on('click', function(){
            $(this).toggleClass('stop');
            if($(this).hasClass('stop')){
              videoElement.pause();
            } else {
              videoElement.play();
            }
          });
        }
        
        let itemOnEnter = () => {
          $this.addClass('is-playing');
          videoElement.play();
        }
        let itemOnLeave = () => {
          $this.removeClass('is-playing');
          videoElement.pause();
        }

        var mediaTl = gsap.timeline({
          scrollTrigger: {
            trigger: $this[0],
            start: "top 80%",
            end: "bottom 10%",
            // markers: true,
            onEnter: itemOnEnter,
            onEnterBack: itemOnEnter,
            onLeave: itemOnLeave,
            onLeaveBack: itemOnLeave,
          }
        });
      }
    })
  }

  if($('[data-type="tab"]').exists()){
    var tabs = $('[data-type="tab"]');
    
    tabs.each(function(){
      var $this = $(this);
      var tabNavItem = $this.find('[data-tab]');
      var tabNavContent = $this.find('[data-tab-content]');
      
      tabNavItem.each(function(){
        if($(this).hasClass('selected')){
          var tabContent = $(this).data('tab');
          $this.find('[data-tab-content = '+  tabContent + ']').slideDown();
        }
      })

      tabNavItem.on('click', function(e){
        e.preventDefault();
        var tabContent = $(this).data('tab');
        tabNavItem.removeClass('selected');
        $(this).addClass('selected');
        tabNavContent.slideUp();
        $this.find('[data-tab-content = '+  tabContent + ']').slideDown();
      })
    })
  }
  
  productDettIntroH()

  // Image Swap in product dett page
  if($('.image-swap').exists()){
    $('.image-swap').each(function(){
      var $this = $(this);
      var viewer = $this.find('.image-swap__viewer');
      var viewerSwitch = viewer.find('.over');
      var viewerSwitchImg = viewerSwitch.find('img');
      var viewerNav = $this.find('.image-swap__nav');
      var viewerNavItem = viewerNav.find('.item');

      viewerNavItem.each(function(){
        var $item = $(this);
        var imageSwitch = $item.data('image');
        
        $item.on({
          "mouseenter" : function(e){
            var _delay = (viewerSwitch.hasClass("show")) ? 800 : 0 ;
                                    
            if(imageSwitch != undefined) {
              viewerSwitchImg.on("load", function() {
                viewerSwitch.stop(true);
                viewerSwitch.delay(_delay).addClass('show');
              }).attr("src", imageSwitch);
            }
          },
          "mouseleave" : function(e){
            viewerSwitch.stop(true);
            viewerSwitch.delay(50).removeClass('show');
          },
        })
      })
    })
  }

  if(document.querySelector('#productConfigurator')){
    let productConfiguratorElement = document.querySelector('#productConfigurator');
    let scrollingPropagation = productConfiguratorElement.querySelector('.scrolling-propagation');
    let iframeActive = 0;
    let cta = scrollingPropagation.querySelector('.cta');
    let productConfiguratorIframe = productConfiguratorElement.querySelector('#iframeConfigurator');
    
    if(cta != null){
      cta.addEventListener('click', function(){
          document.querySelector('html').style.overflowY = 'unset';
          productConfiguratorElement.classList.add('active');
          disableBodyScroll(productConfiguratorElement);
          if(iframeActive == 0) {
            productConfiguratorElement.classList.add('loaded');
            productConfiguratorIframe.setAttribute("src", productConfiguratorIframe.getAttribute('data-src'));
            iframeActive = 1;
          }
      });
    }

    productConfiguratorElement.addEventListener('mouseleave', function(){
        document.querySelector('html').style.overflowY = 'scroll';
        productConfiguratorElement.classList.remove('active');
        enableBodyScroll(productConfiguratorElement);
    });
  }
  
  // Timeline
  if($('.timeline').exists()){
    var inputs = $('.timeline .input');
    var paras = $('.timeline--description').find('.title');
    var currentInput = $('.timeline .input.active');

    timelineFillingUpdate(currentInput);

    timelineInterval = setInterval(function(){
      timelineAuto(inputs, paras)
    }, 3000);
    
    inputs.each(function(){
      var t = $(this);
      var ind = t.index();
      var matchedPara = paras.eq(ind);
      var dot = t.find('.dot');

      dot.on('click', function(){
        timelineFillingUpdate(t);

        t.add(matchedPara).addClass('active');
        inputs.not(t).add(paras.not(matchedPara)).removeClass('active');

        clearInterval(timelineInterval);

        timelineInterval = setInterval(function(){
          timelineAuto(inputs, paras)
        }, 3000);
      })
    });

  }

  // Contacts Map
  if ($(".map").exists() && $(".map #map").exists()) {
    var map;

    const GoogleMapsLoader = new Loader({
      apiKey: _gmapkey,
      version: "weekly",
      libraries: ["places"],
    });

    GoogleMapsLoader.load()
      .then(() => {
        map = new google.maps.Map(document.getElementById("map"), {
          center: { lat: params.latitude, lng: params.longitude },
          zoom: 8,
          maxZoom: 18,
          streetViewControl: false,
          mapTypeControl: false,
          scrollwheel: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
          },
        });

        var image = {
          url: "" + _root + "img/marker.png",
          size: new google.maps.Size(130, 110),
        };
        var myMarker = new google.maps.Marker({
          position: { lat: params.latitude, lng: params.longitude },
          map: map,
          icon: image,
        });
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(myMarker.getPosition());
        map.fitBounds(bounds);
      })
      .catch((e) => {
        // do something
      });
  }

  // Form
  if ($(".thom-form").exists()) {
    $(".thom-form").each(function(){    
      var myForm = $(this);
      myForm.thomForm({
        errorMsg: myForm.find(".thom-form__error"),
        errorCallback: function(myTitle, myMessage) {
          if (myTitle) {
            myTitle = "<div class='paragraph'><strong>" + myTitle + "</strong></div>";
          }
          if (myMessage) {
            myMessage = "<div class='paragraph'>" + myMessage + "</div>";
          }

          if (myTitle || myMessage) {
            myForm.find(".thom-form__error").html(myTitle + myMessage);
          }

          myForm.find(".thom-form__error").fadeIn(300);

          $('html,body').animate({
            scrollTop: myForm.offset().top - $('.navbar').height()
          }, 600);
        },
        myCallback: function(myTitle, myMessage) {
          MyThomFormResponseAction(myTitle, myMessage);
        }
      });
    })
  }

  // News
  // News list
  // if ($('.thom-search--results .news-list').exists()) {
    
  //   // News list / pagination / filters
  //   if ($(".thom-search--filter-block").exists()) {
            
  //     let ThomAdvancedSearch = $('.thom-search').thomsearch({
  //       filterClass: '.thom-search--filter-btn',
  //       filtersContent: '.thom-search--filter-block',
  //       itemsPerPage: THOM_searchItemsPerPage,
  //       paginationChild: '.thom-search__pagination .page-item',
  //       resultsCallback: function (data) {
  //         $('.thom-search--results').html(data);  
  //         var items = $('.thom-search--results').find('.news-list--item');

  //         for(var i = 0; i < items.length; i++){
  //           var item = items[i];

  //           if ($(item).find(".lazy").length > 0) {
  //             myLazyLoad.update();
  //           }

  //           if((i + 1) == (items.length)){
  //             ThomAdvancedSearch.thomsearch('loadingHide');
  //           }
  //         }
  //       },
  //       urlContent: THOM_searchUrlContent
  //     });
  //   }

  // }

  // Filters
  if ($("#search-result").exists()){
    FiltersAndPagination();
  }

  // Accordion
  if ($(".accordion-list").exists()) {
    $(".accordion-list").each(function(){
      var $list = $(this);
      var elms = $list.find(".accordion-item");
      $(elms).each(function(){
        var el = $(this);

        if($(this).hasClass('open')){
          $(this).find(".accordion-content").slideDown(300, function(){           
            jobControl(el);
          });
        }
      });      

      elms.find('.accordion-title').on('click', function(){
        var $this = $(this).parent();

        if($list.hasClass('close-all')){
          if($this.hasClass('open')){
            $this.find(".accordion-content").stop().slideUp(300, function(){
              $this.removeClass('open');
            });
          } else {
            elms.find(".accordion-content").stop().slideUp(300);
            elms.removeClass('open');

            $this.find(".accordion-content").stop().slideDown(300, function(){
              jobControl($this);
            });

            $this.toggleClass("open");
          }
        } else {
          $this.toggleClass("open");
        }
      })
    });
  }
  
  // Open gallery from photogallery button
  if ($(".btn-gallery").exists()) {
    $(".btn-gallery").each(function () {
      var $this = $(this);
      var $lggallery = $("#" + $this.data("gallery"));

      lightGallery(document.getElementById($this.data("gallery")), {
        plugins: [lgVideo],
        licenseKey: 'EB342F23-EEA8-48C2-B726-0CFCE5A4AECF',      
        selector: "img",
        counter: false,
        download: false,
        getCaptionFromTitleOrAlt: false,
        vimeoPlayerParams: {
          byline : 0,
          portrait : 0,
          dnt: true
        },
        youTubePlayerParams: {
          modestbranding : 1,
          showinfo : 0,
          controls : 0,
          rel: 0
        }
      });

      $this.click(function (e) {
        e.preventDefault();
        $lggallery.find("img").first().trigger("click");
      });
    });
  }

  // add this code
  Flickity.prototype._createResizeClass = function () {
    this.element.classList.add("flickity-resize");
  };
  Flickity.createMethods.push("_createResizeClass");
  var FlickityResize = Flickity.prototype.resize;
  if ($(".flickity-resize").exists()) {
    Flickity.prototype.resize = function () {
      var $this = this;
      $(".flickity-resize").each(function () {
        $this.element.classList.remove("flickity-resize");
        FlickityResize.call($this);
        $this.element.classList.add("flickity-resize");
      });
    }
  }

  // Ajax modal
  $('a[rel="ajax:modal"]').off('click');
  $('a[rel="ajax:modal"]').on( 'click', function (event) {
    event.preventDefault();
    this.blur();

    $('.page-loader').addClass('show');

    $.ajax({
      url: $(this).attr("href"),
      success: function (newHTML, textStatus, jqXHR) {
        var myHtml = $(newHTML).find(".ajax-load");
        $(myHtml).appendTo("body");
        var newModal = $(myHtml).modal();
        newModal.on($.modal.OPEN, function(event, modal) {
          let simpleBar;
          if(deviceType() !== "mobile"){
            simpleBar = new SimpleBar(modal.$elm.find('.scroller')[0], { });
          }
          $('.page-loader').removeClass('show');
        }) 
      }
    });
    return false;
  });

  // Inline modal 
  if ($('.inline-modal[data-modal]').exists()){
    $('.inline-modal[data-modal]').off('click');
    $('.inline-modal[data-modal]').on('click', function (event) {  
      event.preventDefault();
      this.blur();
      var filesmodal = $($(this).data("modal"));
      filesmodal.modal();
    });
  }

  // scrollto
  $('[data-scrollto]').on('click', function(){
    var el = $(this).data('scrollto');
    $("html,body").animate(
      {
        scrollTop: $('#' + el).offset().top,
      },
      600
    );
  });

  $(window).on('resize', function() {
      rtime = new Date();
      if (timeout === false) {
          timeout = true;
          setTimeout(resizeend, delta);
      }
  });

  // update analytics
  if (typeof analyticsUpdate === "function") {
    analyticsUpdate();
  }
}

var rtime;
var timeout = false;
var delta = 200;

function resizeend() {
  if (new Date() - rtime < delta) {
      setTimeout(resizeend, delta);
  } else {
      timeout = false;
      if($('body').hasClass('navbar--opened') && window.matchMedia("(min-width: 1200px)").matches){
        $('body').removeClass('navbar--opened')
      }
      productDettIntroH();
  }               
}

function productDettIntroH() {
  if($('.product-dett-intro').exists()){
    var h = $('.product-dett-intro .media').first().height();
    $('.product-dett-intro .text').css('min-height', h)
  }
}

function timelineFillingUpdate(t) {
  var fillingLine = document.querySelector('.filling-line');
  var left = t[0].offsetLeft;
  var width = t.width();

  left = Number(left) + (Number(width)/2);

  if(t.index() === $('.timeline .input').length - 1){
    left = "100%"
  }

  gsap.to(fillingLine, {
    width: left
  });
}

function timelineAuto(inputs, paras) {
  var s = inputs.length;
  var currentInput = $('.timeline .timeline--wrap .input.active');
  var ind = currentInput.index() + 1;
  if(ind >= s){
    ind = 0;
  }

  var next = inputs.eq(ind);
  var matchedPara = paras.eq(ind);

  timelineFillingUpdate(next);
  next.add(matchedPara).addClass('active');
  inputs.not(next).add(paras.not(matchedPara)).removeClass('active');

}

// BARBA JS
// start script
// barba.use(barbaCss);

// define a global hook
// barba.hooks.once((data) => {
//   console.log('once')
//   navbar();
//   myapp();

//   // Remove appended div to modal after close
//   $(document).on($.modal.AFTER_CLOSE, function (event, modal) {
//     $(".ajax-load.modal").remove();
//   });

//   // Analytics update
//   $(document).on($.modal.OPEN, function (event, modal) {
//     $(modal.$anchor).find(".close-modal").attr("data-barba-prevent", "self");
//   });

//   $(".page-transition").removeClass("show");
// });


// barba.hooks.enter((data) => {
//   // console.log("enter");
//   $.ajax({
//     url: data.next.url.href,
//     success: function (newHTML, textStatus, jqXHR) {
//       // Meta update
//       var headTags = [
//         "meta[name='keywords']",
//         "meta[name='description']",
//         "meta[property^='og']",
//         "meta[name^='twitter']",
//         "meta[itemprop]",
//         "link[itemprop]",
//         "link[rel='prev']",
//         "link[rel='next']",
//         "link[rel='canonical']",
//       ].join(",");
//       $("head").find(headTags).remove();
//       $(newHTML).find(headTags).appendTo("head");

//       // Menu Change
//       var newMenu = $(newHTML).find(".navbar__collapse").html();
//       $(".navbar .navbar__collapse").html(newMenu);

//       // Preview Menu Change
//       var newPreviewMenu = $(newHTML).find(".navbar__menu-preview").html();
//       $(".navbar .navbar__menu-preview").html(newPreviewMenu);

//       // Logo Menu Change
//       var newNavSx = $(newHTML).find(".navbar__sx").html();
//       $(".navbar .navbar__sx").html(newNavSx);

//       // script variables
//       // let js = data.next.container.querySelectorAll('main script');
//       // console.log(js)
//       // if(js != null){
//       //     js.forEach((item) => {
//       //         eval(item.innerHTML);
//       //     });
//       // }

//       var js = $(newHTML).find("script");
//       if (js != null) {
//         $.globalEval(js.html());
//       }
      
//       myapp();
//     },
//     error: function (xhr, ajaxOptions, thrownError) {
//       alert(xhr.status)
//       alert(xhr.statusText)
//       // alert(thrownError)
//     },
//     complete: function () {
//     },
//   });
// });

// barba.hooks.beforeLeave((data) => {
//   // console.log("beforeLeave");
//   // spinner().show();
//   $(".page-transition").addClass("animIn");

//   // document.querySelector('.js-navbar').classList.remove('on-stage', 'out-stage');
//   // navbar().navbarAddScrollListener;
// })

// barba.hooks.leave((data) => {
//   // this hook will be called for each transitions
//   // console.log("leave 1");

//   if(timelineInterval){
//     clearInterval(timelineInterval);
//   }

//   $.modal.close();  
// });

const theme = {
  value: window.matchMedia('(prefers-color-scheme: goldsmith)').matches ? 'goldsmith' : 'dental'
}
theme.value = theme.value === 'dental' ? 'goldsmith' : 'dental';

// barba.hooks.beforeEnter((data) => {
//   console.log('beforeEnter')

//   theme.value = data.next.container.dataset.theme;
//   document.firstElementChild.setAttribute('data-theme', theme.value);
  

//   $(".wrapper").removeAttr("style");
//   $(".main").removeAttr("style");

//   $("body").removeClass("navbar--opened");
  
//   $("html,body").animate({
//     scrollTop: 0,
//   }, 0);

//   $(".navbar").removeClass("out-stage, on-stage--ico-menu, tween");
  
//   ScrollTrigger.getAll().forEach(t => {
//     t.kill()
//   });

// });

// barba.hooks.afterEnter((data) => {
//   console.log('afterEnter')
//   // spinner().hide();
//   $(".navbar").addClass("tween");
//   $(".page-transition").removeClass("animIn");

//   var hash = $(location).attr('hash');
//   if(hash){
//     $("html,body").animate({
//         scrollTop: $(hash).offset().top,
//       },400);
//   }
// });

// Back button and barba history
if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}
let scrollPosY = [0];

document.addEventListener("DOMContentLoaded", () => {
  barba.init({
    timeout: 9000,
    cacheIgnore: true,
    prefetchIgnore: true,
    debug: FinalizationRegistry,
    transitions: [{
      async once(data) {
        theme.value = data.next.container.dataset.theme;
        document.firstElementChild.setAttribute('data-theme', theme.value);

        await navbar();
        await contentAnimation();
      },
      async leave(data) {
        await pageTransitionIn(data);
        // console.log('transitions leave')
      },
      async enter(data) {
        await pageTransitionOut(data.next.container, data, data.next.namespace);
        // console.log('transitions enter')      
      },
      async beforeLeave(data) {
        // console.log('transitions before Leave')
      },
      async afterEnter(data) {
        // console.log('transitions after Enter')
      },
      async afterLeave(data) {
        data.current.container.remove()
        
        var newHTML = data.next.html;
        var headTags = [
          "meta[name='keywords']",
          "meta[name='description']",
          "meta[property^='og']",
          "meta[name^='twitter']",
          "meta[itemprop]",
          "link[itemprop]",
          "link[rel='prev']",
          "link[rel='next']",
          "link[rel='canonical']",
        ].join(",");

        $("head").find(headTags).remove();
        $(newHTML).find(headTags).appendTo("head");

        // Menu Change
        var newMenu = $(newHTML).find(".navbar__collapse").html();
        $(".navbar .navbar__collapse").html(newMenu);

        // Preview Menu Change
        var newPreviewMenu = $(newHTML).find(".navbar__menu-preview").html();
        $(".navbar .navbar__menu-preview").html(newPreviewMenu);

        // Logo Menu Change
        var newNavSx = $(newHTML).find(".navbar__sx").html();
        $(".navbar .navbar__sx").html(newNavSx);

        ScrollTrigger.getAll().forEach(t => {
          t.kill()
        });

        let tlChildren = gsap.globalTimeline.getChildren();
        if(tlChildren){
            tlChildren.forEach(child => {
                child.kill();
                child = null;
            });
        }

        // Inject scripts on page
        const js = data.next.container.querySelectorAll("script");
        [].forEach.call(js, function (script) {
          // console.log(script);
          window.eval(script.innerHTML);
        });

        $(".wrapper").removeAttr("style");
        $(".main").removeAttr("style");

        // console.log('transitions after Leave')
      },
      async beforeEnter(data) {
        theme.value = data.next.container.dataset.theme;
        document.firstElementChild.setAttribute('data-theme', theme.value);

        // console.log('transitions before Enter')
      }
    }]
  });
});

function pageTransitionOut(container, data, namespace) {
  // console.log('page Transition Out')

  if(data && data.trigger !== "back") {
      scrollPosY.push(barba.history.current.scroll.y);
  }

  if(data.trigger !== "back") {
      window.scrollTo(0, 0, "instant");
    } else {
      window.scrollTo(0, scrollPosY.pop())
  }

  if(timelineInterval){
      clearInterval(timelineInterval);
  }
  
  // GSAP methods can be chained and return directly a promise
  return contentAnimation(container, data, namespace)
}

function pageTransitionIn(data) {
  $(".page-transition").addClass("animIn");

  $.modal.close();  
}

function contentAnimation(container, data, namespace) {
  (async () => {
      // console.log('content Animation')

      let startMyApp = await myapp();
      let hideLoading = await pageTransitionLoadingHide();
      return {
          startMyApp,
          hideLoading
       };
  })()    
}

function pageTransitionLoadingHide() {
  $(".page-transition").removeClass("show");

  $(".navbar").addClass("tween");
  $(".page-transition").removeClass("animIn");

  var hash = $(location).attr('hash');
  if(hash){
    $("html,body").animate({
        scrollTop: $(hash).offset().top,
      },400);
  }
}
